import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',

        layout: 'full',
        breadcrumb: [
          {

            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-connection',
      name: 'error-connection',
      component: () => import('@/views/error/ErrorConnection.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-no-data',
      name: 'error-no-data',
      component: () => import('@/views/error/ErrorNoData.vue'),
      meta: {
        layout: 'full',
      },
    },
    
    {
      path: '/error-close',
      name: 'error-close',
      component: () => import('@/views/error/ErrorClose.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/:resturant',
      name: 'product',
      component: () => import('@/views/Product.vue'),
      meta: {
        pageTitle: 'المنتجات',
        breadcrumb: [
          {
            text: 'المنتجات',
            active: true,
          },
        ],
      },
    },
    {
      path: '/:resturant/:template',
      name: 'product',
      component: () => import('@/views/Product.vue'),
      meta: {
        pageTitle: 'المنتجات',
        breadcrumb: [
          {
            text: 'المنتجات',
            active: true,
          },
        ],
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
// Remove initial loading
// const appLoading = document.getElementById('loading-bg')
// if (appLoading) {
//   appLoading.style.display = 'none'
// }
// })

export default router
