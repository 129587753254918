import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import './assets/svg/index'
import 'core-js'

import axios from 'axios'
import VueI18n from 'vue-i18n'
import router from './router'
import store from './store'
import App from './App.vue'
// import i18n from './libs/i18n'
// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

const lang = localStorage.getItem('lang') || 'en'
axios.defaults.baseURL = 'http://localhost:8080'
axios.defaults.headers['Accept-Language'] = lang
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueI18n)
const messages = {}
// const messages = {
//   en: {
//     message: {
//       value: 'This is an example of content translation.',
//     },
//   },
//   ar: {
//     message: {
//       value: 'مثال للغة',
//     },
//   },
//   fr: {
//     message: {
//       value: 'Dette er et eksempel på oversættelse af indhold.',
//     },
//   },
// }
const i18n = new VueI18n({
  locale: 'en',
  messages,
})
// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
